import React from 'react';
import { styles } from '../styles';

const Navigation = ({ onNavClick, activeSection }) => {
  const navItems = ['home', 'about', 'achievements'];

  return (
    <nav style={{
      ...styles.navigation,
      display: 'flex',
      width: '100%',
    }}>
      {navItems.map((item) => (
        <button
          key={item}
          onClick={() => onNavClick(item)}
          style={{
            flex: 1,
            padding: '1rem',
            fontSize: '1.1rem',
            fontWeight: activeSection === item ? 'bold' : 'normal',
            background: activeSection === item ? '#f3f4f6' : 'white',
            border: 'none',
            borderBottom: activeSection === item ? '2px solid #4a90e2' : '1px solid #e5e7eb',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
          }}
        >
          {item.charAt(0).toUpperCase() + item.slice(1)}
        </button>
      ))}
    </nav>
  );
};

export default Navigation;